import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './HeadingWithSubHeading.module.css'

const HeadingWithSubHeading = ({
    children,
    subHeading,
    icon,
    align,
    contrast,
}) => {
    let contrastClass
    if (contrast) {
        contrastClass =
            typeof contrast === 'boolean'
                ? styles.contrast__primary
                : styles[`contrast__${contrast}`]
    }
    return (
        <div className={[styles[align], styles.row, contrastClass].join(' ')}>
            {icon ? <img src={icon} className={styles.icon} alt="" /> : ''}
            <div style={{ width: '100%' }}>
                <span className={styles.subheading}>{subHeading}</span>
                {children}
            </div>
        </div>
    )
}

export default HeadingWithSubHeading

HeadingWithSubHeading.propTypes = {
    subHeading: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    align: PropTypes.oneOf(['left', 'center', 'right']),
    contrast: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.oneOf(['primary', 'alt']),
    ]),
}
