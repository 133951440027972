import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero/Hero'
import Container from '../components/container'
import HeadingWithSubHeading from '../components/HeadingWithSubHeading/HeadingWithSubHeading'
import CompanyGridTop from '../components/page-components/company/CompanyGridTop/CompanyGridTop'
import CompanyTeamGrid from '../components/page-components/company/CompanyTeamGrid/CompanyTeamGrid'

const IntroBox = styled.div`
    background-color: var(--color-blue);
    color: #fff;
    padding: 2rem 3rem;
    margin-bottom: 4rem;
    margin-top: -3rem;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;

    @media (min-width: 769px) {
        font-size: 1.3rem;
    }
`

const Company = ({ data }) => {
    const { image1, image2, teamData } = data
    const images = [image1, image2]
    const allTeam = teamData.edges.map(({ node: teamMember }) => teamMember)
    const leadership = allTeam.filter(member => member.title !== 'Jim Hogan')
    const emeritus = allTeam.filter(member => member.title === 'Jim Hogan')

    return (
        <Layout>
            <SEO title="Company" />
            <Hero image={data.heroImage} title="Company" />

            <Container>
                <IntroBox>
                    Bluespec is driving the adoption of RISC-V processors for
                    use in FPGAs. Working in partnership with FPGA vendors,
                    Bluespec provides optimized and pre-verified RISC-V cores
                    and tools to enable customers to efficiently integrate,
                    program, accelerate and verify RISC-V cores from
                    best-in-class RISC-V open-source projects. We enable
                    companies to take advantage of RISC-V’s cost reduction and
                    freedom to innovate without the support and productization
                    risks of open-source hardware.
                </IntroBox>
                <HeadingWithSubHeading subHeading="The Team" align="center">
                    <h3 className="display">Leadership</h3>
                </HeadingWithSubHeading>
                <CompanyTeamGrid team={leadership} />
                {emeritus.length > 0 && (
                    <>
                        <HeadingWithSubHeading align="center">
                            <h3 className="display">BOARD EMERITUS</h3>
                        </HeadingWithSubHeading>
                        <CompanyTeamGrid team={emeritus} />
                    </>
                )}

                <hr css={{ marginBottom: '6rem' }} />
                <CompanyGridTop images={images} />
            </Container>
        </Layout>
    )
}

export default Company

export const query = graphql`
    query {
        image1: file(relativePath: { eq: "Company-Photo1.jpg" }) {
            ...squareImage
        }
        image2: file(relativePath: { eq: "Company-Photo2.jpg" }) {
            ...squareImage
        }
        heroImage: file(relativePath: { eq: "company-bg@2x.png" }) {
            ...heroImage
        }
        teamData: allWpTeamMember(limit: 20) {
            edges {
                node {
                    ...teamFields
                }
            }
        }
    }
`
