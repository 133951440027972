/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import { Link } from 'gatsby'
import * as styles from './CompanyTeamGrid.module.css'
import AvatarPlaceholder from '../../../../images/avatar.jpeg'
import Img from 'gatsby-image'
import Modal from 'react-modal'
import striptags from 'striptags'

class CompanyTeamGrid extends Component {
    constructor() {
        super()

        this.state = {
            modalIsOpen: false,
            currentModalTeamMember: null,
        }

        this.openModal = this.openModal.bind(this)
        this.afterOpenModal = this.afterOpenModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    openModal(teamMember) {
        this.setState({
            modalIsOpen: true,
            currentModalTeamMember: teamMember,
        })
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    closeModal() {
        this.setState({ modalIsOpen: false })
    }

    render() {
        const { team } = this.props
        return (
            <div className={styles.wrap}>
                <div>
                    {team.map((teamMember, index) => (
                        <div className={styles.item} key={index}>
                            <div className={styles.meta}>
                                <span className={styles.itemName}>
                                    {teamMember.title}
                                </span>
                                <span className={styles.itemTitle}>
                                    {teamMember.meta.title}
                                </span>
                            </div>
                            <div className={styles.itemExcerpt}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: striptags(teamMember.excerpt),
                                    }}
                                />{' '}
                                <button
                                    onClick={() => this.openModal(teamMember)}
                                    className={styles.button}
                                >
                                    View Full Bio
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    className={styles.modal}
                    overlayClassName={styles.modalOverlay}
                >
                    {this.state.currentModalTeamMember && (
                        <>
                            <div className={styles.modalHeader}>
                                <h2>
                                    {this.state.currentModalTeamMember.title}
                                </h2>
                                <button
                                    onClick={this.closeModal}
                                    className={[
                                        styles.button,
                                        styles.closeButton,
                                    ].join(' ')}
                                >
                                    &times;
                                </button>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: this.state.currentModalTeamMember
                                        .content,
                                }}
                            />
                        </>
                    )}
                </Modal>
            </div>
        )
    }
}

export default CompanyTeamGrid
