// extracted by mini-css-extract-plugin
export var wrap = "CompanyTeamGrid-module--wrap--2uNYv";
export var item = "CompanyTeamGrid-module--item--18Hlr";
export var meta = "CompanyTeamGrid-module--meta--1PPK-";
export var itemExcerpt = "CompanyTeamGrid-module--itemExcerpt--D6Mbg";
export var itemName = "CompanyTeamGrid-module--itemName--1N_wE";
export var itemTitle = "CompanyTeamGrid-module--itemTitle--2XdOF";
export var itemHeadshot = "CompanyTeamGrid-module--itemHeadshot--3HoHR";
export var grid = "CompanyTeamGrid-module--grid--314vN";
export var button = "CompanyTeamGrid-module--button--2O67N";
export var closeButton = "CompanyTeamGrid-module--closeButton--3HVY3";
export var modal = "CompanyTeamGrid-module--modal--2vUA0";
export var modalHeader = "CompanyTeamGrid-module--modalHeader--sTJAI";
export var modalOverlay = "CompanyTeamGrid-module--modalOverlay--xLmPy";