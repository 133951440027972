import React from 'react'
import * as styles from './CompanyGridTop.module.css'
import HeroWithSubHeading from '../../../HeadingWithSubHeading/HeadingWithSubHeading'
import CharlieQuoteImg from '../../../../images/CharlieQuote-Updated.png'

const CompanyGridTop = () => (
    <div className={styles.gridContainer}>
        <div className={styles.story}>
            <HeroWithSubHeading subHeading="Our History" align="left">
                <h3 className="display">Founding Story</h3>
                <div style={{ textAlign: 'left' }}>
                    <p>
                        Bluespec was founded by a team of experts from the
                        embedded systems and hardware industry. The mission was
                        to develop tools and IP that removed barriers for
                        developers. Our team has a long history of innovations
                        enabling the hardware world to design and program at the
                        speed of software.
                    </p>
                    <p>
                        With the emergence of RISC-V, Bluespec has bolstered
                        their commitment to this mission by introducing
                        industry-leading technology that allows for easy core
                        evaluation, software development, acceleration, and
                        verification. Bluespec is also known for its open source
                        IP family of cores.
                    </p>
                    <p>
                        Bluespec’s leadership is active in the community, as a
                        member of the Open-HW organization, serving on the
                        RISC-V Foundation Board of Directors and chairing the
                        Formal Model Technical Committee.
                    </p>
                </div>
            </HeroWithSubHeading>
        </div>
        <div className={styles.quote}>
            <img src={CharlieQuoteImg} alt="Quote from Charlie Hauck" />
        </div>
    </div>
)

export default CompanyGridTop
